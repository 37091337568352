import React from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

import Screen from '../components/Screen';
import Card from '../components/Card';
import Copyright from '../components/Copyright';
import logo from '../graphics/EH_2TM_LogoFull@2x.png';

const EntryScreen = () => <Screen>
  <Card>
    <img alt='logo' className='w-36 mb-3' src={logo}></img>
    <Outlet />
  </Card>
  <div className='mb-6'>
    <Copyright />
  </div>
</Screen>;

const mapDispatchToProp = dispatch => ({
  updateAuthState: state => dispatch({ type: 'UPDATE_AUTH', state }),
  updateLoadingState: loading => dispatch({ type: 'UPDATE_LOADING', loading }),
});

export default connect(({ authenticated }) => ({ authenticated }), mapDispatchToProp)(EntryScreen);
