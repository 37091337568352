import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { calculateTime } from '../utils/formatting';

const Timer = ({ timing, game, tick, time }) => {
  const [timer, setTimer] = useState(null);

  const toggle = () => timing && game ? setTimer(setInterval(tick, 1000)) : timer ? clearInterval(timer) : null;
  
  const calcTimePercentage = time => {
    const percetage = ((time / 60) * 100) % 100 ;
    return `${percetage} ${100 - percetage}`
  };

  useEffect(() => {
    toggle()
  }, [timing, game]);

  return <svg viewBox="0 0 60 26" width="13" height="5" x="2" y="1">
    <svg x={0} y={8} width="41%" height="41%" viewBox="0 0 66 66" class="donut">
      <circle transform='rotate(-90, 32, 32)' class="donut-segment" cx="32" cy="32" r="15.91549430918954" fill="transparent" stroke="#fff" stroke-width={32} stroke-dasharray={calcTimePercentage(time)} />
    </svg>
    <text style={styles.timer} x="20" y="18">
      {calculateTime(time)}
    </text>
  </svg>;
};

const mapStateToProps = ({ time, timing, game,}) => ({ time, timing, game,});

const mapDispatchToProps = dispatch => ({ tick: () => dispatch({ type: 'TICK' }) });

const styles = {
  container: {
    margin: '0 auto'
  },
  timer: {
    fill: '#FFF',
    fontWeight: 'bold',
    alignText: 'center'
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
