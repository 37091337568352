import React from 'react';
import Level from './Level';
import { connect } from 'react-redux';

const Game = ({ eggs, levels, currentLevel }) => {
  const l = levels.map((l, i) => <Level
    {...l}
    eggs={eggs.slice(i * 5, i * 5 + 5)}
    c={currentLevel}
  />)
  return <div className='w-screen fixed' >
    {l[currentLevel]}
  </div >
};

export default connect(({ eggs, levels, currentLevel }) => ({ eggs, levels, currentLevel }))(Game);