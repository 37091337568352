import React from 'react';

import Reveal from './Reveal';
import stamp from '../graphics/EggHunt_LevelCompletedIcon.svg';

const CompleteOverlay = () => <Reveal duration={500}>
  <rect width={100} height={65} x={0} fill="#ffffff99" clip-path="url(#clip)" />
  <image href={stamp} width={20} x={40} y={17} />
</Reveal>;

export default CompleteOverlay;