import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import { createUser, getOrg } from '../firebase';
import Button from './Button';
import '../stripe.css';
import Input from './Input';
import rocket from '../graphics/rocket.svg';

const Login = ({ updateLoadingState, fillItem }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { email, firstName, lastName, acknowledgement } = useSelector(({ email, firstName, lastName, acknowledgement }) => ({ email, firstName, lastName, acknowledgement }));
  const [details, setDetails] = useState(null);
  const [check, setCheck] = useState(null);

  useEffect(() => {
    let canceled = false;

    if (!params?.org)
      return;

    const effect = async () => {
      const details = await getOrg({ id: params.org, justDoc: true });

      if (canceled) throw new Error('Task has been canceled');

      dispatch({ type: 'SET_ORG', org: params.org });
      setDetails(details);
    };

    effect();

    return () => canceled = true;
  }, [params]);

  const submitUser = async () => {
    // @TODO: Clean this validation up.
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!params?.org)
      throw new Error('There is no set org.')

    if (!lastName || !firstName) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your input!', body: 'You must enter your first and last name.' } });
      return;
    }

    if (!String(email).toLowerCase().match(emailRegEx)) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your input!', body: 'Your email is not valid. You must enter a valid email.' } });
      return;
    }

    if (!acknowledgement) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your input!', body: `You must acknowledge that you've read the Terms and Conditions and that you are at least 18 years old.` } });
      return;
    }

    updateLoadingState(true);
    // @TODO: Validate input
    await createUser({ email, firstName, lastName, org: params.org, acknowledgement });
    navigate('/h');
  };

  return <>
    <div className='flex place-self-start w-72'>
      <div className='border-l-8 border-primary-purple self-stretch rounded-lg mr-3 mt-1' />
      <div className='flex flex-col text-left justify-center'>
        {details ? <>
          <div className='text-dark-purple font-black text-2xl mb-[-4px] p-0'>{details?.orgName.toUpperCase()}</div>
          <div className='text-gray-700 text-xs font-semibold m-0 p-0'>{details?.orgCity}, {details?.orgState.toUpperCase()}</div>
        </> : <>
          <div className='animate-pulse bg-dark-purple h-5 w-32 rounded-lg my-1' />
          <div className='animate-pulse bg-dark-purple h-4 w-24 rounded-lg' />
        </>}
      </div>
    </div>
    <div className='w-full flex flex-col space-y-4 p-3 bg-gray-300 rounded-4xl w-[375px]'>
      <Input
        id='login-firstName'
        name='firstName'
        type='text'
        placeholder="Parent's First Name"
        label='Parent/Guardian First Name'
        required={true}
        onChange={({ target }) => fillItem({ item: 'firstName', value: target.value })}
      />
      <Input
        id='login-lastName'
        name='lastName'
        type='text'
        placeholder="Parent's Last Name"
        label='Parent/Guardian Last Name'
        required={true}
        onChange={({ target }) => fillItem({ item: 'lastName', value: target.value })}
      />
      <Input
        id='login-Email'
        name='Email'
        type='email'
        placeholder="Email"
        label='Email'
        required={true}
        onChange={({ target }) => fillItem({ item: 'email', value: target.value })}
      />
    </div>
    <div className='flex space-x-2'>
      <div
        className={`flex justify-center items-center ${check ? 'bg-sky-500' : 'bg-gray-500'} rounded-md w-4 h-4 m-[3px] transition-all duration-1000`}
        onClick={() => {
          const timestamp = new Date();
          if (check){
            setCheck(null);
            fillItem({ item: 'acknowledgement', value: null });
          }
          else {
            setCheck(timestamp);
            fillItem({ item: 'acknowledgement', value: timestamp });
          }
        }}
      >
        <div className={`transition-all duration-100 ${check ? 'opacity-1' : 'opacity-0'}`}>
          <svg width={13} xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
            <title>Checkmark</title>
            <path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="64" d="M416 128L192 384l-96-96" />
          </svg>
        </div>
      </div>
      <div className='text-xxs w-56'>
        By clicking here, I state that I have read and understood the terms and conditions and that I am at least 18 years of age.
      </div>
    </div>
    <Button className='bg-primary-purple' disabled={!details} loading={!details} onClick={submitUser}>
      <div>
        SUBMIT
      </div>
      <img alt='logo' className='w-12' src={rocket} />
    </Button>
  </>;
};

const mapDispactToProps = dispatch => ({
  fillItem: data => dispatch({ type: 'FILL_FORM_ITEM', ...data }),
  setOrg: data => dispatch({ type: 'SET_ORG', ...data }),
  updateLoadingState: loading => dispatch({ type: 'UPDATE_LOADING', loading }),
});


export default connect(null, mapDispactToProps)(Login);