import React, { useEffect, useState } from 'react';
import anime from 'animejs';
import { connect } from 'react-redux';
import basketFront from '../graphics/basket_Front.svg';
import basketBack from '../graphics/basket_Back.svg';
import basketEggsFront from '../graphics/EggApp_BasketEggs_Front.svg';
import basketEggsBack from '../graphics/EggApp_BasketEggs_Back.svg';
import { getCurrentUserData } from '../firebase';


const animate = ({ ref, complete }) => anime({
  targets: ref,
  keyframes: [
    {
      delay: 1500,
    },
    {
      duration: 300,
      opacity: 1
    },
    {
      duration: 1500,
      opacity: 0
    },
  ],
  easing: 'easeOutExpo',
  complete
});

const Plus = () => {
  const [ref, setRef] = useState(null);
  const [l, setL] = useState(null);

  useEffect(() => {
    if (!ref)
      return;

    animate({
      ref,
      complete: () => setL(true)
    });
  }, [ref]);

  return !l && <text ref={setRef} style={{ opacity: 0, fill: '#00FF91', fontWeight: 'bold' }} x={8} y={10}>+</text>;
};

const Score = ({ score, eggs }) => {
  const [displayed, setDisplayed] = useState(score);
  const [basket, setBasket] = useState(score);
  const [pluses, setPluses] = useState([]);

  useEffect(() => {
    if (displayed !== score) {
      setTimeout(() => {
        setDisplayed(score);
        setTimeout(() => setBasket(score), 500);
      }, 2000);
      setPluses([Plus, ...pluses]);
    }
  }, [score]);

  return (
    <svg viewBox="0 0 60 80" width="18" height="18" x="83.5" y="43">
      {/* <image href={basketBack} width="55" x="2" y="20" /> */}
      <image href={basketEggsBack} width="22" x="18" y={30 - (9 * basket / eggs.length)} />
      {/* <text ref={setRef} style={{ opacity: 0, fill: '#00FF91', fontWeight: 'bold' }} x={9} y={15}>+</text> */}
      {pluses.length && pluses.map((C, i) => <C key={i} />)}
      <image href={basketEggsFront} width="30" x="15" y={32 - (9 * basket / eggs.length)} />
      <image href={basketFront} width="55" x="2" y="20" />
      <text style={styles} x={displayed < 10 ? 25.6 : 22.4} y="47.2">
        {displayed}
      </text>
    </svg>
  );
};

const styles = {
  // fill: '#00ACFF',
  fill: '#FFF',
  borderStyle: 'solid',
  fontWeight: 'bold',
  fontSize: '9pt',
  alignText: 'center'
}

const mapStateToProps = ({ score, animation, eggs }) => ({ score, animation, eggs });

export default connect(mapStateToProps)(Score);