import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Sel from 'react-select';

import { getOrgList } from '../firebase';
import rocket from '../graphics/rocket.svg';
import Button from './Button';


const Select = ({ setOrg }) => {
  const [list, setList] = useState(null);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let canceled = false;

    const f = async () => {
      const orgList = await getOrgList();
      if (canceled) throw new Error('Task has been cancelled.');
      setList(orgList);
    };

    f();

    return () => {
      canceled = true;
    }
  }, []);

  const navTo = () => {
    setOrg({ org: selected });
    navigate(`/o/${selected}`)
  };

  return <div className='w-[300px] space-y-5 flex flex-col justify-center'>
    <Sel
      className='w-full rounded-full'
      placeholder='find your org...'
      options={list?.map(i => ({
        label: `${i.orgName} | ${i.orgCity}${i.orgState ? `, ${i.orgState}` : ''}`,
        value: i.id
      })) || []}
      onChange={opt => setSelected(opt.value)}
    />
    <Button className='bg-primary-purple' disabled={!selected || !list?.length} loading={!list} onClick={navTo}>
      <div>CONTINUE</div>
      <img alt='logo' className='w-12' src={rocket} />
    </Button>
    <Button className='bg-teal' onClick={() => window.location.href = `https://org.egg-hunt.com/`}>
      CREATE AN ORG
    </Button>
  </div>;
};

const mapDispactToProps = dispatch => ({
  fillItem: data => dispatch({ type: 'FILL_FORM_ITEM', ...data }),
  setOrg: data => dispatch({ type: 'SET_ORG', ...data }),
  updateLoadingState: loading => dispatch({ type: 'UPDATE_LOADING', loading }),
  setToast: value => dispatch({ type: 'ADD_TOAST', value })
});

const mapStateToProps = ({ email, firstName, lastName }) => ({ email, firstName, lastName });

export default connect(mapStateToProps, mapDispactToProps)(Select);