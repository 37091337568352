import React from 'react';

const Canvas = props => <svg
  className='flex-1 m-auto max-w-full'
  viewBox={[0, 0, 100, 56]}
  // width="100%"
  height="95vh"
>
  <defs>
    <rect id="rect" width={96} height={56} x={2} fill="none" stroke="#00000000" stroke-width="3" rx="3" />
    <clipPath id="clip">
      <use href="#rect" />
    </clipPath>
    <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
      <path d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
        style={{ stroke: '#f1f1f1', strokeWidth: 1.5 }} />
    </pattern>
  </defs>
  <use href="#rect" stroke="#00000000" />
  <rect width={100} height={65} x={0} fill="#e1e1e1" clip-path="url(#clip)" />
  <rect width={100} height={65} x={0} fill="url(#diagonalHatch)" clip-path="url(#clip)" />
  {props.children}
</svg>


export default Canvas;