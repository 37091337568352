import { useEffect, useState } from "react";

const Toast = ({ title, body }) => {
  const [close, setClose] = useState(false);
  useEffect(() => {
    let canceled = false;

    const timer = setTimeout(() => {
      if (canceled) throw new Error('Task has been canceled.');
      setClose(true);
    }, 7000);

    return () => {
      canceled = true;
      clearTimeout(timer);
    };
  }, []);

  return !close && <div className='z-50 flex items-center shadow bg-primary-purple rounded-xl p-4 w-64'>
    <div className='border-l-8 border-dark-purple self-stretch rounded-lg mr-2 mt-1' />
    <div className='flex flex-col text-left justify-center'>
      <div className='text-teal font-bold text-sm'>{title}</div>
      <div className='text-white text-xs'>
        {body}
      </div>
    </div>
  </div>
};

export default Toast;