import React, { useState, useEffect } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GoldenEgg from '../graphics/EggApp_HiddenEgg_Golden.svg';
import BlueEgg from '../graphics/EggApp_HiddenEgg_Blue.svg';
import GreenEgg from '../graphics/EggApp_HiddenEgg_Green.svg';
import PurpleEgg from '../graphics/EggApp_HiddenEgg_Purple.svg';
import YellowEgg from '../graphics/EggApp_HiddenEgg_Yellow.svg';

const selectEgg = type => {
  switch (type) {
    case 'blue':
      return BlueEgg;
    case 'green':
      return GreenEgg;
    case 'yellow':
      return YellowEgg;
    case 'purple':
      return PurpleEgg;
    default:
      return GoldenEgg;
  }
};

const animate = ({ direction, ref, x, y, ...rest }) => anime({
  targets: ref,
  keyframes: [
    {
      translateX: direction.x || 0,
      translateY: direction.y || 0,
      duration: 1000,
      // easing: 'spring(1, 30, 80, 15)',
      easing: 'easeOutExpo',
    },
    {
      translateX: 90.5 - x,
      translateY: 43 - y,
      duration: 1000,
      width: 3,
      height: 3,
      easing: 'easeOutExpo',
    },
    {
      translateY: (42 - y) + 7,
      duration: 600,
      easing: 'easeInExpo',
    },
  ],
  ...rest
});

const Egg = props => {
  const { size, found, type, x, y, direction, setCompleted, completed, i } = props;
  const [ref, setRef] = useState(null);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (!done && found) {
      animate({ x, y, direction, ref, complete: () => setCompleted({ index: i }) })
      setDone(true);
    }
    if (!completed && !found) setDone(false);
  }, [found, ref, x, y, direction, done]);

  return !completed && <image
    ref={setRef}
    style={{
      transformOrigin: `${x + (size / 2)}px ${y + (size / 2)}px 0`
    }}
    href={selectEgg(type)}
    width={size}
    height={size}
    {...props}
  />;
};

Egg.propTypes = {
  size: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  found: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({ setCompleted: data => dispatch({ type: 'COMPLETED', ...data }) });

export default connect(null,mapDispatchToProps)(Egg);