import { useEffect } from 'react';

import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';

const DemoScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/o/demo-org-7f42')
  }, []);

  return <Loading />;
};

export default DemoScreen;