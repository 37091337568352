import React from 'react';

const Link = ({ children, ...props }) => <a
  className='underline hover:text-teal'
  target="_blank"
  rel="noopener noreferrer"
  {...props}
>
  {children}
</a>;

const Copyright = () => {
  return (
    <div className='mt-4 mx-auto text-white text-center text-xs w-64'>
      <div>
        {'By submitting you agree to '}
        <Link href="https://app.termly.io/document/terms-and-conditions/b64f6de9-fab7-4d18-9711-c0eeab1565d4">
          Terms of Use
        </Link>{'.'}
      </div>
      <div>
        <Link href="https://app.termly.io/document/privacy-policy/ab6afc33-69a5-4456-9697-ca6dadd31dd9">
          Privacy Policy
        </Link>
        {', '}
        <Link href="https://app.termly.io/document/disclaimer/06928e6e-16f5-46f1-baef-d55fc315cfd5">
          Disclaimer
        </Link>
        {', and '}
      </div>
      <div>
        <Link href="https://app.termly.io/document/acceptable-use-policy/8b96397a-efbc-4141-8291-848ea50508c2">
          Acceptable Use Policy
        </Link>
      </div>
      <div>
        {'Created by '}
        <Link href="https://noodlegroup.com/">
          Noodle Group LLC.
        </Link>
      </div>
      <div>
        {'Copyright © '}
        <Link href="https://noodlegroup.com/">
          Noodle Group LLC.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </div>
    </div>
  );
};

export default Copyright;