import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getNumberOfTries, signOutUser } from '../firebase';
import Game from '../components/Game';
import BeginModal from '../components/BeginModal';
import EndModal from '../components/EndModal';
import bg from '../graphics/background_pattern.svg';

const HomeScreen = ({ updateLoadingState, game, score, totalEggs, time, org }) => {
  const [tries, setTries] = useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let canceled = false;

    const effect = async () => {
      if (canceled) throw new Error('Task has been cancelled.');
      updateLoadingState(true);

      try {
        const tries = await getNumberOfTries();
        if (canceled) throw new Error('Task has been cancelled.');
        setTries(tries);

        if (!tries) {
          dispatch({
            type: 'ADD_TOAST',
            value: {
              title: `You've run out of tries!`,
              body: 'Please use a different email if you would like to continue playing.'
            }
          });
          await signOutUser();
          navigate(`/o/${org}`);
        }

        updateLoadingState(false);
      }
      catch (error) {
        dispatch({
          type: 'ADD_TOAST',
          value: {
            title:
              `Something has gone wrong!`,
            body: 'Please try again in a little bit.'
          }
        });
        navigate(`/o/${org}`);
      }
    };

    effect();

    // Not supported on Safari
    // document.getElementById('game-view').requestFullscreen();

    return () => canceled = true;
  }, []);

  return <div
    id='game-view'
    className='w-screen min-h-screen bg-[#635dde] bg-repeat flex'
    style={{ backgroundImage: `url(${bg})` }}
  >
    <div className='flex items-center'>
      <Game />
    </div>
    {!game && !(!tries || score === totalEggs || time <= 0) ? <BeginModal /> : null}
    {!tries || score === totalEggs || time <= 0 ? <EndModal /> : null}
  </div>;
};

// @TODO: Refactor to use useSelector and useDispatch.
export default connect(({ org, time, eggs, score, game }) => ({ org, time, totalEggs: eggs.length, score, game }),
  dispatch => ({
    setOrg: data => dispatch({ type: 'SET_ORG', ...data }),
    toggleLevel: () => dispatch({ type: 'SWITCH_LEVEL' }),
    updateLoadingState: loading => dispatch({ type: 'UPDATE_LOADING', loading }),
    updateTries: tries => dispatch({ type: 'UPDATE_TRIES', tries })
  })
)(HomeScreen);