import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const TouchPoint = props => {
  const { x, y, size, found, i, add, level } = props;
  
  const r = size / 2;
  
  const clicked = () => {
    if (found) return;
    add({ index: i, level });
  };
  
  return <svg>
    <circle onClick={clicked} fill='transparent' cx={x + r} cy={y + r} r={r} />
  </svg>;
};

TouchPoint.propTypes = {
  size: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  found: PropTypes.bool.isRequired,
};

// const mapDispatchToProps = d => ({ add: data => d({ type: 'levels/found', ...data }) });
const mapDispatchToProps = dispatch => ({ add: data => dispatch({ type: 'ADD_SCORE', ...data }) });

export default connect(null, mapDispatchToProps)(TouchPoint);