import { useEffect } from 'react';
import { connect } from 'react-redux';
import Image from './Image';
import ProgressiveImage from 'react-progressive-image';
import egg from '../graphics/egg.svg';
import Reveal from './Reveal';

const Painting = ({
  startTimer,
  stopTimer,
  base,
  overlay,
  width,
  scrollX,
  scrollY,
  children
}) => {
  useEffect(() => {
    stopTimer();
  }, []);

  return <svg>
    <ProgressiveImage src={require(`../bases/${base}`)}>
      {(src0, loading0) => <ProgressiveImage src={require(`../overlays/${overlay}`)}>
        {(src1, loading1) => {
          if (!loading1 && !loading0) {
            startTimer();
            return <Reveal tag='g'>
              <Image
                width={width}
                scrollX={scrollX}
                scrollY={scrollY}
                image={src0}
              />
              {children}
              <Image
                width={width}
                scrollX={scrollX}
                scrollY={scrollY}
                image={src1}
              />
            </Reveal>
          }
          else {
            stopTimer();
            return <image className='animate-pulse' href={egg} x={40} y={20} width={25} />
          }
        }}
      </ProgressiveImage>}
    </ProgressiveImage>
  </svg>;
}

const mapDispatchToProps = d => ({
  stopTimer: () => d({ type: 'STOP_TIMER' }),
  startTimer: () => d({ type: 'START_TIMER' })
});

export default connect(null, mapDispatchToProps)(Painting);