const initialState = {
  tries: 0,
  firstName: '',
  lastName: '',
  email: '',
  acknowledgement: null,
  org: null,
  orgName: null,
  loading: true,
  authenticated: null,
  //
  score: 0,
  time: 300,
  totalTime: 300,
  timing: false,
  game: false,
  currentLevel: 0,
  toasts: [],
  eggs: [
    // 0
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 59.8,
      y: 22.5,
      size: 2,
      found: false,
      i: 0
    },
    {
      direction: { x: 0, y: 2 },
      completed: false,
      x: 33,
      y: 44.5,
      size: 2,
      found: false,
      i: 1,
      type: 'green'
    },
    {
      direction: { x: 1, y: -3 },
      completed: false,
      x: 22,
      y: 20.5,
      size: 4,
      found: false,
      i: 2,
      type: 'blue'
    },
    {
      direction: { x: 2, y: 0.1 },
      completed: false,
      x: 79.8,
      y: 27,
      size: 3,
      found: false,
      i: 3,
      type: 'yellow'
    },
    {
      direction: { x: 2, y: 0 },
      completed: false,
      x: 44.4,
      y: 14.7,
      size: 2,
      found: false,
      i: 4,
      type: 'purple'
    },
    // 1
    {
      direction: { x: 2, y: -3 },
      completed: false,
      x: 15,
      y: 30.4,
      size: 3,
      found: false,
      i: 5,
      type: 'green'
    },
    {
      direction: { x: 0, y: -5 },
      completed: false,
      x: 55.2,
      y: 19.2,
      size: 2,
      found: false,
      i: 6,
      type: 'green'
    },
    {
      direction: { x: -5.5, y: 2.5 },
      completed: false,
      x: 83.45,
      y: 33.68,
      size: 3,
      found: false,
      i: 7,
      type: 'blue'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 47.5,
      y: 31.65,
      size: 2,
      found: false,
      i: 8,
      type: 'yellow'
    },
    {
      direction: { x: 3, y: -1 },
      completed: false,
      x: 54.6,
      y: 5.8,
      size: 3,
      found: false,
      i: 9,
      type: 'purple'
    },
    // 2
    {
      direction: { x: -3, y: -2 },
      completed: false,
      x: 36,
      y: 13.75,
      size: 1.5,
      found: false,
      i: 10,
      type: 'green'
    },
    {
      direction: { x: 1, y: 3 },
      completed: false,
      x: 42,
      y: 28,
      size: 4,
      found: false,
      i: 11,
      type: 'green'
    },
    {
      direction: { x: 4, y: 1.75 },
      completed: false,
      x: 58.53,
      y: 46.65,
      size: 5,
      found: false,
      i: 12,
      type: 'blue'
    },
    {
      direction: { x: 2, y: 2 },
      completed: false,
      x: 72.5,
      y: 9,
      size: 2,
      found: false,
      i: 13,
      type: 'yellow'
    },
    {
      direction: { x: -3, y: -3 },
      completed: false,
      x: 50,
      y: 10.5,
      size: 3,
      found: false,
      i: 14,
      type: 'purple'
    },
    // 3
    {
      direction: { x: -4, y: -2 },
      completed: false,
      x: 88,
      y: 33.8,
      size: 3,
      found: false,
      i: 15
    },
    {
      direction: { x: -2, y: -5 },
      completed: false,
      x: 44,
      y: 29.5,
      size: 5,
      found: false,
      i: 16,
      type: 'green'
    },
    {
      direction: { x: -2, y: -2 },
      completed: false,
      x: 56.3,
      y: 8,
      size: 2.5,
      found: false,
      i: 17,
      type: 'yellow'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 12,
      y: 46.5,
      size: 3,
      found: false,
      i: 18,
      type: 'green'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 59.25,
      y: 41.75,
      size: 3,
      found: false,
      i: 19,
      type: 'purple'
    },
    // 4
    {
      direction: { x: -4, y: -2 },
      completed: false,
      x: 40,
      y: 34,
      size: 4,
      found: false,
      i: 20
    },
    {
      direction: { x: 3, y: 0 },
      completed: false,
      x: 18.5,
      y: 38,
      size: 5.75,
      found: false,
      i: 21,
      type: 'green'
    },
    {
      direction: { x: 0, y: -5 },
      completed: false,
      x: 43.9,
      y: 10.5,
      size: 3,
      found: false,
      i: 22,
      type: 'blue'
    },
    {
      direction: { x: 3, y: -3 },
      completed: false,
      x: 74.4,
      y: 15.65,
      size: 5,
      found: false,
      i: 23,
      type: 'green'
    },
    {
      direction: { x: 1, y: -3 },
      completed: false,
      x: 71.5,
      y: 31.35,
      size: 3,
      found: false,
      i: 24,
      type: 'purple'
    },
    // 5
    {
      direction: { x: 0, y: -4 },
      completed: false,
      x: 48.4,
      y: 16.4,
      size: 3,
      found: false,
      i: 25
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 40,
      y: 36,
      size: 2,
      found: false,
      i: 26,
      type: 'purple'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 60,
      y: 7,
      size: 2,
      found: false,
      i: 27,
      // type: 'blue'
    },
    {
      direction: { x: 2, y: -2 },
      completed: false,
      x: 75.9,
      y: 13.7,
      size: 2,
      found: false,
      i: 28,
      type: 'green'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 16.5,
      y: 43,
      size: 3,
      found: false,
      i: 29,
      type: 'purple'
    },
    // 6
    {
      direction: { x: 2, y: 2 },
      completed: false,
      x: 68.7,
      y: 16.7,
      size: 3,
      found: false,
      i: 30
    },
    {
      direction: { x: 0, y: 3 },
      completed: false,
      x: 83,
      y: 7.7,
      size: 4,
      found: false,
      i: 31,
      type: 'green'
    },
    {
      direction: { x: 2, y: -5 },
      completed: false,
      x: 11.9,
      y: 45.8,
      size: 4,
      found: false,
      i: 32,
      type: 'blue'
    },
    {
      direction: { x: 2, y: -4 },
      completed: false,
      x: 45,
      y: 15.7,
      size: 3,
      found: false,
      i: 33,
      // type: 'yellow'
    },
    {
      direction: { x: -1, y: 3 },
      completed: false,
      x: 22,
      y: 13.5,
      size: 4,
      found: false,
      i: 34,
      type: 'green'
    },
    // 7
    {
      direction: { x: 0, y: 3 },
      completed: false,
      x: 38,
      y: 46.9,
      size: 3,
      found: false,
      i: 35,
      type: 'green'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 30,
      y: 33.5,
      size: 4,
      found: false,
      i: 36,
      // type: 'green'
    },
    {
      direction: { x: 2, y: 1 },
      completed: false,
      x: 37,
      y: 8,
      size: 3,
      found: false,
      i: 37,
      type: 'blue'
    },
    {
      direction: { x: 3, y: 0 },
      completed: false,
      x: 57.8,
      y: 10,
      size: 5,
      found: false,
      i: 38,
      type: 'yellow'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 31.5,
      y: 22.9,
      size: 2,
      found: false,
      i: 39,
      // type: 'purple'
    },
    // 8
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 8,
      y: 8.2,
      size: 3,
      found: false,
      i: 40,
      type: 'blue'
    },
    {
      direction: { x: -2, y: -6 },
      completed: false,
      x: 21,
      y: 46.2,
      size: 5,
      found: false,
      i: 41,
      type: 'purple'
    },
    {
      direction: { x: 1, y: -4 },
      completed: false,
      x: 42.3,
      y: 14.8,
      size: 3,
      found: false,
      i: 42,
      // type: 'blue'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 69.23,
      y: 20.7,
      size: 1.5,
      found: false,
      i: 43,
      type: 'green'
    },
    {
      direction: { x: 0, y: -6 },
      completed: false,
      x: 13.5,
      y: 22.55,
      size: 4,
      found: false,
      i: 44,
      type: 'yellow'
    },
    // 9
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 18,
      y: 14.5,
      size: 3,
      found: false,
      i: 45,
      type: 'green'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 25.2,
      y: 34.5,
      size: 3,
      found: false,
      i: 46,
      type: 'blue'
    },
    {
      direction: { x: 0, y: -1 },
      completed: false,
      x: 75.6,
      y: 18.5,
      size: 1.5,
      found: false,
      i: 47,
      type: 'blue'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 60.1,
      y: 22.2,
      size: 2,
      found: false,
      i: 48,
      type: 'purple'
    },
    {
      direction: { x: 0, y: -3 },
      completed: false,
      x: 31,
      y: 7.25,
      size: 2,
      found: false,
      i: 49,
      // type: 'purple'
    },
  ],
  levels: [
    {
      base: 'EH_2TM_L1_BG.png',
      overlay: 'EH_2TM_L1_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L2_BG.png',
      overlay: 'EH_2TM_L2_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L3_BG.png',
      overlay: 'EH_2TM_L3_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L4_BG.png',
      overlay: 'EH_2TM_L4_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L5_BG.png',
      overlay: 'EH_2TM_L5_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L6_BG.png',
      overlay: 'EH_2TM_L6_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L7_BG.png',
      overlay: 'EH_2TM_L7_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L8_BG.png',
      overlay: 'EH_2TM_L8_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L9_BG.png',
      overlay: 'EH_2TM_L9_OV.png',
      scroll: 0,
    },
    {
      base: 'EH_2TM_L10_BG.png',
      overlay: 'EH_2TM_L10_OV.png',
      scroll: 0,
    }
  ]
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case 'ADD_SCORE':
      return {
        ...state,
        score: state.score + 1,
        animation: !state.animation,
        eggs: state.eggs.map((o, i) => i === action.index ? { ...o, found: true } : o)
      };
    case 'COMPLETED':
      return {
        ...state,
        eggs: state.eggs.map((o, i) => i === action.index ? { ...o, completed: true } : o)
      };
    case 'ADD_ORG_NAME':
      return {
        ...state,
        orgName: action.orgName
      };
    case 'START_GAME':
      return {
        ...state,
        game: true,
        timing: true,
      };
    case 'STOP_GAME':
      return {
        ...state,
        game: false
      };
    case 'START_TIMER':
      return {
        ...state,
        timing: true,
      };
    case 'STOP_TIMER':
      return {
        ...state,
        timing: false
      };
    case 'TICK':
      return {
        ...state,
        time: state.time > 0 ? state.time - 1 : 0
      }
    case 'FILL_FORM_ITEM':
      return {
        ...state,
        [`${action.item}`]: action.value
      };
    case 'UPDATE_AUTH':
      return {
        ...state,
        authenticated: action.state
      };
    case 'SET_ORG':
      return {
        ...state,
        org: action.org
      };
    case 'UPDATE_LOADING':
      return {
        ...state,
        loading: action.loading
      };
    case 'SWITCH_LEVEL':
      return {
        ...state,
        currentLevel: state.currentLevel + 1 < state.levels.length ? state.currentLevel + 1 : 0
      };
    case 'UPDATE_TRIES':
      return {
        ...state,
        tries: action.tries,
      };
    case 'RESET':
      const { tries, firstName, lastName, email, org, loading, authenticated, ...resetState } = initialState;
      return {
        ...state,
        ...resetState
      };
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: [...state.toasts, action.value]
      };
    default:
      return state;
  }
}

export default reducer;
