import Spinner from "./Spinner";

const Button = ({ children, className, disabled, loading, ...rest }) => <button
  {...rest}
  disabled={disabled}
  className={`py-2 px-6 text-white rounded-full font-black flex space-x-2 justify-center w-full items-center disabled:bg-gray-500 disabled:ring-0 disabled:ring-offset-0 disabled:scale-100 ${className} transition duration-300 hover:ring-4 ring-offset-2 ring-primary-purple hover:scale-95`}
  type='submit'
>
  {loading ? <div className='my-1'><Spinner /></div> : children}
</button>;

export default Button;