import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';

import { authStateListener } from './firebase';
import EntryScreen from './screens/EntryScreen';
import DemoScreen from './screens/DemoScreen';
import Select from './components/Select';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Toasts from './components/Toasts';
import Admin from './components/Admin';

const ProtectedHomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticated, org } = useSelector(({ authenticated, org }) => ({ authenticated, org }));
  useEffect(() => {
    if (!authenticated) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Uh-oh', body: 'You have not sign-in yet!' } });
      navigate(org ? `/${org}` : '/');
    }
  }, [authenticated]);

  return <HomeScreen />;
};

const InvalidRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { org } = useSelector(({ org }) => ({ org }));

  useEffect(() => {
    dispatch({ type: 'ADD_TOAST', value: { title: 'Uh-oh', body: 'The attempted route is not valid.' } });
    navigate(org ? `/o/${org}` : '/');
  }, []);

  return <EntryScreen />;
}

const App = ({ updateAuthState, updateLoadingState }) => {
  useEffect(() => {
    let cb = () => { };
    let canceled = false;

    const effect = async () => {
      cb = await authStateListener(updateAuthState);
      if (canceled) throw new Error('Task has been cancelled.');
      updateLoadingState(false);
    };

    effect();

    return () => {
      canceled = true;
      // cb();
    };
  }, []);

  return <>
    <Toasts />
    <Routes>
      <Route path='/' element={<EntryScreen />}>
        <Route index element={<Select />} />
        <Route path='/o/:org' element={<Login />} />
        <Route path='/s' element={<SignUp />} />
      </Route>
      <Route path='/admin/:org/:key' element={<Admin />} />
      <Route path='/admin/:org' element={<Admin />} />
      <Route path='/admin' element={<Admin />} />
      <Route path='/h' element={<ProtectedHomeScreen />} />
      <Route path='/d' element={<DemoScreen />} />
      <Route path="*" element={<InvalidRoute />} />
    </Routes>
  </>
};

const mapDispatchToProp = dispatch => ({
  updateAuthState: state => dispatch({ type: 'UPDATE_AUTH', state }),
  updateLoadingState: loading => dispatch({ type: 'UPDATE_LOADING', loading }),
});

export default connect(({ authenticated, toasts }) => ({ authenticated, toasts }), mapDispatchToProp)(App);
