import React, { useEffect, useState } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';

const Reveal = ({ children, duration = 1750, tag = 'g' }) => {
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (!ref)
      return;

    anime({
      targets: ref,
      opacity: 1,
      duration,
      easing: 'easeInExpo',
    });
  }, [ref]);

  return tag === 'g' ? <g ref={setRef} opacity={0}>{children}</g>
    : <div ref={setRef} style={{ opacity: 0 }}>{children}</div>;
};

Reveal.propTypes = {
  duration: PropTypes.number,
  tag: PropTypes.string,
};

export default Reveal;
