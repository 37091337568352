import React, { useState, Component } from 'react'
import { connect, useDispatch } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import '../stripe.css'

import { createPaymentIntent, createOrg } from '../firebase';
import Input from './Input';
import Button from './Button';
import { useNavigate } from 'react-router-dom';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#000',
      color: '#aaa',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#aaa',
      },
      '::placeholder': {
        color: '#aaa',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="p-3 bg-gray-300 rounded-full w-full">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled, onClick }) => (
  <Button
    // className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    className={`bg-primary-purple mx-auto w-full p-3 ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
    loading={processing}
    onClick={onClick}
  >
    {children}
  </Button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
  });

  const [orgDetails, setOrgDetails] = useState({
    orgName: '',
    orgCity: '',
    orgState: '',
    size: 0,
    amount: 0
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { email, phone, name } = billingDetails;
    const { orgName, orgCity, orgState, size } = orgDetails;

    // @TODO: Clean this validation up.
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const phoneRegEx = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;

    const sizeRegEx = /[0-9]*/;

    if (!name) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'You must enter a your name.' } });
      return;
    }

    if (!String(email).toLowerCase().match(emailRegEx)) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'Your email is not valid.' } });
      return;
    }

    if (!phone) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'You must enter a phone number.' } });
      return;
    }

    if (!phone.match(phoneRegEx)) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'Your phone number must be valid.' } });
      return;
    }

    if (!orgName) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'You must enter a name for your organization' } });
      return;
    }

    if (!orgCity) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'You must enter the city your organization is located.' } });
      return;
    }

    if (!orgState) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'You must enter the state your organization is located.' } });
      return;
    }

    if (!size) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'You must enter the size of your organization.' } });
      return;
    }

    if (!size.match(sizeRegEx)) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your form!', body: 'You must enter a valid size for your organization.' } });
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete || orgDetails.amount <= 0) {
      setProcessing(true);
    }

    const { success, message } = await createOrg({ ...billingDetails, ...orgDetails });

    if (!success && message === 'EMAIL ALREADY USED') {
      dispatch({
        type: 'ADD_TOAST',
        value: {
          title: 'Email has already been used!',
          body: 'The email that you entered has already been used. Please use a valid email that you have not used for an Organization and/or Player account.'
        }
      });
      setProcessing(false);
      return;
    }
    else if (!success) {
      dispatch({
        type: 'ADD_TOAST',
        value: {
          title: 'An issue occurred!',
          body: 'Something went wrong, please try again later.'
        }
      });
      setProcessing(false);
      return;
    }

    if (orgDetails.amount > 0) {
      const { data } = await createPaymentIntent({ amount: orgDetails.amount* 100 });

      const res = await stripe.confirmCardPayment(data.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: billingDetails
        }
      });

      if (res.error) {
        setError(res.error);
        setProcessing(false);
        return;
      }
    }

    setProcessing(false);
    navigate('/admin');
  };

  return <>
    <div className='flex flex-col w-[375px] space-y-4'>
      <div className='flex flex-col bg-gray-300 p-3 rounded-4xl w-full space-y-1'>
        <div className='ml-3 mt-1 place-self-start text-xs text-gray-700 font-bold'>Organization Info</div>
        <Input
          // label="Name"
          id="name"
          type="text"
          placeholder="Full Name*"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
        />
        <Input
          // label="Email"
          id="email"
          type="email"
          placeholder="Email*"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value });
          }}
        />
        <Input
          // label="Phone"
          id="phone"
          type="tel"
          placeholder="Phone*"
          required
          autoComplete="tel"
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, phone: e.target.value });
          }}
        />
        <Input
          id='orgName'
          name='orgName'
          type='text'
          // label='Org Name'
          required={true}
          placeholder='Organization name*'
          fullWidth
          variant='filled'
          color='secondary'
          onChange={(e) => setOrgDetails({ ...orgDetails, orgName: e.target.value })}
        />
        <div className='flex space-x-1'>
          <Input
            id='orgCity'
            name='orgCity'
            type='text'
            placeholder='City*'
            // label='Org City'
            color='secondary'
            variant='filled'
            style={{ width: '60%' }}
            required={true}
            onChange={(e) => setOrgDetails({ ...orgDetails, orgCity: e.target.value })}
          />
          <Input
            id='orgState'
            name='orgState'
            type='text'
            // label='Org State'
            required={true}
            placeholder='State*'
            variant='filled'
            color='secondary'
            style={{ width: '36%' }}
            value={orgDetails.orgState}
            onChange={(e) => setOrgDetails({
              ...orgDetails,
              orgState: e.target.value.length < 3 ? e.target.value.toUpperCase() : orgDetails.orgState
            })}
          />
        </div>
        <Input
          id='size'
          name='size'
          type="number"
          placeholder='Org Size*'
          // label='Org Size'
          required={true}
          variant='filled'
          color='secondary'
          InputLabelProps={{
            shrink: true,
          }}
          // style={{ width: '31%' }}
          onChange={(e) => setOrgDetails({ ...orgDetails, size: e.target.value })}
        />
      </div>
      <div className='flex flex-col bg-gray-300 p-3 rounded-4xl w-full space-y-1'>
        <div className='ml-3 mt-1 place-self-start text-xs text-gray-700'><span className='font-bold'>Donate</span> | 100% goes to support <a className='text-gray-700 underline hover:text-teal' target="_blank" rel="noopener noreferrer" href={'https://convoyofhope.org/'}>Convoy of Hope</a></div>
        <div className='flex items-center'>
          <div className='text-gray-700 mr-1 ml-3'>$</div>
          <Input
            name='amount'
            type="number"
            placeholder='0'
            // label='Donation (optional)'
            required={false}
            variant='filled'
            color='secondary'
            InputLabelProps={{
              shrink: true,
            }}
            value={orgDetails.amount || null}
            onChange={(e) => setOrgDetails({ ...orgDetails, amount: Math.abs(Number(String(e.target.value).replace(/[^0-9]*/g, ""))) })}
          />
        </div>
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </div>
    <SubmitButton onClick={handleSubmit} processing={processing} error={error} disabled={!stripe}>
      Donate ${orgDetails.amount || 0}
    </SubmitButton>
    <div className='text-xxs text-gray-700'>
      Powered by <a className='hover:text-teal underline' target="_blank" rel="noopener noreferrer" href={'https://stripe.com/'}>Stripe</a>
    </div>
  </>;

};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripePromise: null
    }
  }

  componentDidMount = async () => {
    const stripePromise = await loadStripe('pk_live_a1McE0nfZfxBVqRN8df8LK4t');
    this.setState({ stripePromise })
  };

  render() {
    return this.state.stripePromise && <Elements stripe={this.state.stripePromise}>
      <CheckoutForm />
    </Elements>;
  }
};

const mapDispactToProps = dispatch => ({
  updateLoadingState: loading => dispatch({ type: 'UPDATE_LOADING', loading }),
});

const mapStateToProps = ({ email, firstName, lastName }) => ({ email, firstName, lastName });

export default connect(mapStateToProps, mapDispactToProps)(Login);