import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { createPlayer, getNumberOfTries } from '../firebase';
import { useCancelToken } from '../hooks/useCancelToken';
import Button from './Button';
import Card from './Card';
import Input from './Input';
import rocket from '../graphics/rocket.svg';
import greeting from '../graphics/EH_2TM_GreetingImage.png';

const BeginModal = () => {
  const dispatch = useDispatch();
  const { levels, eggs } = useSelector(({ levels, eggs }) => ({ levels, eggs }));

  const [initials, setInitials] = useState('');
  const [prompt, setPrompt] = useState(false);
  const [token, cancel] = useCancelToken()

  const setInitialsValidated = s => {

    if (s.length > 3)
      return;

    setInitials(s.toUpperCase());
  };

  useEffect(() => {
    const f = async () => {
      const tries = await getNumberOfTries();
      if (token.current)
        throw new Error('Task has been cancelled.');
      dispatch({ type: 'UPDATE_TRIES', tries });
    };

    f(token);

    return cancel;
  }, []);

  const start = async () => {
    const tries = await createPlayer({
      score: null,
      initials,
    });

    dispatch({ type: 'UPDATE_TRIES', tries });
    dispatch({ type: 'START_GAME' });
  };

  return <div className='z-10 m-auto'>
    <Card>
      <div className='max-w-[325px] space-y-3 lg:space-y-6 text-center'>
        {!prompt ? <>
          <img className='hidden lg:flex w-full rounded-4xl lg:mb-3 ring-8 ring-teal' src={greeting} />
          <div className='font-black text-2xl text-center lg:text-3xl text-purple lg:text-left'>
            GREETINGS EGG HUNTER
          </div>
          <div className='text-xs lg:text-md text-justify'>
            This year we are shooting off into space. The eggs escaped from the farm, hid on a rocket, and headed out into the cosmos. You will race against the clock to find (click on) all the eggs and receive a score. There are <span className='font-bold text-blue'>{levels?.length || 0} screens</span> with five eggs on each screen. You can hit NEXT at any time to move to the next screen. Each game ends when the time runs out or you have found all <span className='font-bold text-blue'>{eggs?.length} eggs</span>.
          </div>
          <Button className='bg-teal m-auto' onClick={() => setPrompt(true)}>
            NEXT
          </Button>
          <div className='text-xxs text-gray-700'>
            Created by <a className='hover:text-teal underline' target="_blank" rel="noopener noreferrer" href={'https://noodlegroup.com/'}>Noodle Group LLC.</a>
          </div>
        </>
          :
          <>
            <Input
              value={initials}
              onChange={e => setInitialsValidated(e.target.value)}
              id="firstName"
              placeholder='Enter your initials...'
              className='font-bold text-center text-2xl'
            />
            <Button className='bg-teal m-auto' disabled={!initials} onClick={start}>
              <div>
                BEGIN
              </div>
              <img alt='logo' className='w-12' src={rocket} />
            </Button>
          </>}
      </div>
    </Card>
  </div>;
}

export default BeginModal;