import anime from 'animejs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrg, sendOrgKey } from '../firebase';
import Button from './Button';
import Card from './Card';
import Copyright from './Copyright';
import Input from './Input';
import logo from '../graphics/EH_2TM_LogoFull@2x.png';

import Screen from './Screen';
import { useDispatch } from 'react-redux';
import Spinner from './Spinner';

const CopyLink = ({ link }) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!clicked)
      return;

    const timer = setTimeout(() => setClicked(false), 1500);

    return () => clearTimeout(timer);
  }, [clicked]);

  const copy = async () => {
    'clipboard' in navigator ? await navigator.clipboard.writeText(link) : document.execCommand('copy', true, link);
    setClicked(true);
  }

  return <div onClick={copy} className='text-teal font-medium text-md flex space-x-2 items-center'>
    <a href={`https://${link}`} className='hover:underline' target="_blank" rel="noopener noreferrer">
      {link}
    </a>
    <div className='flex flex-col h-10 justify-center hover:cursor-pointer'>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="20" height="20" rx="5" fill="#22F2C9" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.08985 4.96194C8.74034 5.31145 8.53488 5.90287 8.53488 6.91628V7.12791H9.95581C11.0587 7.12791 11.9487 7.3457 12.5515 7.94852C13.1543 8.55134 13.3721 9.44132 13.3721 10.5442V11.9651H13.5837C14.5971 11.9651 15.1885 11.7597 15.5381 11.4101C15.8876 11.0606 16.093 10.4692 16.093 9.45581V6.91628C16.093 5.90287 15.8876 5.31145 15.5381 4.96194C15.1885 4.61244 14.5971 4.40698 13.5837 4.40698H11.0442C10.0308 4.40698 9.43936 4.61244 9.08985 4.96194ZM13.3721 12.8721H13.5837C14.6866 12.8721 15.5766 12.6543 16.1794 12.0515C16.7822 11.4487 17 10.5587 17 9.45581V6.91628C17 5.81341 16.7822 4.92343 16.1794 4.32061C15.5766 3.7178 14.6866 3.5 13.5837 3.5H11.0442C9.94132 3.5 9.05134 3.7178 8.44852 4.32061C7.8457 4.92343 7.62791 5.81341 7.62791 6.91628V7.12791H7.41628C6.31341 7.12791 5.42343 7.3457 4.82061 7.94852C4.2178 8.55134 4 9.44132 4 10.5442V13.0837C4 14.1866 4.2178 15.0766 4.82061 15.6794C5.42343 16.2822 6.31341 16.5 7.41628 16.5H9.95581C11.0587 16.5 11.9487 16.2822 12.5515 15.6794C13.1543 15.0766 13.3721 14.1866 13.3721 13.0837V12.8721ZM7.41628 8.03488C6.40287 8.03488 5.81145 8.24034 5.46194 8.58985C5.11244 8.93936 4.90698 9.53077 4.90698 10.5442V13.0837C4.90698 14.0971 5.11244 14.6885 5.46194 15.0381C5.81145 15.3876 6.40287 15.593 7.41628 15.593H9.95581C10.9692 15.593 11.5606 15.3876 11.9101 15.0381C12.2597 14.6885 12.4651 14.0971 12.4651 13.0837V10.5442C12.4651 9.53077 12.2597 8.93936 11.9101 8.58985C11.5606 8.24034 10.9692 8.03488 9.95581 8.03488H7.41628Z" fill="white" stroke="white" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <div className='text-white text-xs absolute mt-10 -ml-3 text-center pointer-events-none'>{clicked && 'Copied!'}</div>
    </div>
  </div>;
};

const animate = ({ update, users }) => {
  const o = { users: 0 };
  anime({
    targets: [o],
    duration: 2500,
    delay: 250,
    color: '#fff',
    users,
    easing: 'easeOutSine',
    round: 1,
    update: () => update(o)
  });
}
const Admin = () => {
  const { org, key } = useParams();
  const dispatch = useDispatch();
  const [details, setDetails] = useState(null);
  const [users, setUsers] = useState(0);
  const [email, setEmail] = useState(null);
  const [sending, setSending] = useState('');

  useEffect(() => {
    let canceled = false;

    if (!org || !key)
      setDetails({ valid: false });

    const effect = async () => {
      const details = await getOrg({ id: org, key });

      if (canceled)
        throw new Error('Task has been canceled.')

      setDetails(details);
    };

    effect();

    return () => canceled = true;
  }, [org, key]);

  useEffect(() => {
    if (!details?.userCount)
      return;

    animate({
      users: details.userCount,
      update: ({ users }) => setUsers(users),
    });
  }, [details]);

  const submit = async () => {
    // @TODO: Clean this validation up.
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!String(email).toLowerCase().match(emailRegEx)) {
      dispatch({ type: 'ADD_TOAST', value: { title: 'Check your input!', body: 'Your email is not valid. You must enter a valid email.' } });
      return;
    }

    setSending('SENDING');

    const { success } = await sendOrgKey({ email });

    if (success) {
      setSending('SENT')
    }
    else {
      setSending('');
      dispatch({
        type: 'ADD_TOAST',
        value: {
          title: 'Email not found',
          body: `There don't seem to be any Org Admins with the provided email.`
        }
      });
    }
  };

  return <Screen>
    <div className='z-10 m-auto'>
      {(details?.valid ?? true) ? <div className='flex flex-col bg-white items-center rounded-4xl shadow-xl min-w-[400px] m-6 overflow-hidden'>
        <div className='w-[400px] text-center flex flex-col lg:flex-row lg:w-[650px]'>
          <div className='p-4 flex-1 flex flex-col justify-start py-8 lg:pb-8 space-y-2 space-y-9 bg-primary-purple'>
            <div className='flex px-7'>
              <div className='border-l-8 border-dark-purple self-stretch rounded-lg lg:h-[90%] mr-3 mt-1' />
              <div className='flex flex-col text-left justify-center'>
                {details?.orgName ? <>
                  <div className='text-white font-black text-2xl mb-[-4px] p-0'>{details?.orgName?.toUpperCase()}</div>
                  <div className='text-dark-purple text-xs font-semibold m-0 p-0'>{details?.orgCity}, {details?.orgState?.toUpperCase()}</div>
                </> : <>
                  <div className='animate-pulse bg-dark-purple h-5 w-32 rounded-lg my-1' />
                  <div className='animate-pulse bg-dark-purple h-4 w-24 rounded-lg' />
                </>}
              </div>
            </div>
            <div className='flex flex-col space-y-4 justify-center items-center'>
              <div className='flex flex-col justify-between items-between bg-dark-purple p-4 rounded-3xl w-[85%] shadow-xl h-40'>
                <div className={`text-white font-black flex grow justify-center items-center ${users > 9999 ? 'text-6xl' : users > 999 ? 'text-7xl' : 'text-8xl'}`}>
                  {users}
                </div>
                <div className='text-primary-purple text-2xl font-black'>
                  EGG HUNTERS
                </div>
              </div>
              <div className='self-stretch border-b-2 border-dark-purple border-dashed px-2 py-1 mx-8' />
              <CopyLink link={`egg-hunt.com/o/${org}`} />
              <div className='text-white font-medium text-xs'>
                Copy your custom link above and<br /> share it with your community.
              </div>
              <div className='w-full flex flex-col justify-stretch px-7 space-y-5'>
                <a
                  href={`https://us-central1-egg-hunt-7f7bd.cloudfunctions.net/exportOrganizationData/?id=${org}&key=${key}`}
                  className='py-2 px-6 text-white rounded-full font-black flex space-x-2 justify-center w-full items-center disabled:bg-gray-500 disabled:ring-0 disabled:ring-offset-0 disabled:scale-100 bg-teal transition duration-300 hover:ring-4 ring-offset-2 ring-primary-purple hover:scale-95'
                >
                  EXPORT USER DATA
                </a>
                <a
                  download
                  href={`https://firebasestorage.googleapis.com/v0/b/egg-hunt-7f7bd/o/egg-hunt-promo-assets.zip?alt=media&token=39d9c13f-8622-47d3-b52d-c55cff417592`}
                  className='py-2 px-6 text-white rounded-full font-black flex space-x-2 justify-center w-full items-center disabled:bg-gray-500 disabled:ring-0 disabled:ring-offset-0 disabled:scale-100 bg-blue transition duration-300 hover:ring-4 ring-offset-2 ring-primary-purple hover:scale-95'
                >
                  PROMO ASSETS
                </a>
              </div>
            </div>
          </div>
          <div className='p-4 flex-1 flex flex-col justify-start items-center py-8 lg:pb-8 space-y-2 lg:space-y-6'>
            <div className='text-primary-purple font-black text-25'>TOP 10 EGG HUNTERS</div>
            <div className='w-[75%] lg:h-[10px] lg:overflow-y-auto flex flex-col flex-grow'>
              {details?.top10?.length ? details?.top10?.map(({ initials, score }, i) => <div className='flex justify-around border-b-2 border-gray-300 border-dashed px-2 py-1 m-0'>
                <div className='text-gray-700 font-medium text-lg'>{i + 1}</div>
                <div className='text-gray-700 font-medium text-lg'>{initials.toUpperCase()}</div>
                <div className='text-gray-700 font-medium text-lg'>{score}</div>
              </div>) : new Array(10).fill({ initials: '???', score: '???' }).map(({ initials, score }, i) => <div className='animate-pulse flex justify-around border-b-2 border-gray-300 border-dashed px-2 py-1 m-0'>
                <div className='text-gray-700 font-medium text-lg'>{i + 1}</div>
                <div className='text-gray-700 font-medium text-lg'>{initials.toUpperCase()}</div>
                <div className='text-gray-700 font-medium text-lg'>{score}</div>
              </div>)}
            </div>
          </div>
        </div>
      </div> : <Card>
        <img alt='logo' className='w-36 mb-3' src={logo}></img>
        <div className='flex place-self-start w-72'>
          <div className='border-l-8 border-primary-purple self-stretch rounded-lg mr-3 mt-1' />
          <div className='flex flex-col text-left justify-center'>
            <div className='text-dark-purple font-black text-2xl mb-[-4px] p-0'>
              SIGN-IN
            </div>
            <div className='text-gray-700 text-xs font-semibold m-0 p-0'>
              You will be sent an email with an authorized link to access your Admin Dashboard.
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col space-y-4 p-1 bg-gray-300 rounded-4xl w-[375px]'>
          <Input
            id='login-Email'
            name='Email'
            type='email'
            placeholder="Email"
            label='Email'
            required={true}
            onChange={({ target }) => setEmail(target.value)}
          />
        </div>
        <Button disabled={['SENT', 'SENDING'].includes(sending)} loading={['SENDING'].includes(sending)} className='bg-primary-purple w-full' onClick={submit}>
          {sending === 'SENT' ? 'SENT!' : 'SEND'}
        </Button>
        {sending === 'SENT' && <div className='text-primary-purple text-xs'>
          Please check your email for your new link.
        </div>}
      </Card>}
    </div>
    <Copyright />
    <div className='m-5'></div>
  </Screen>;
};

export default Admin;