import React, { useEffect, useState } from 'react';

import Canvas from './Canvas';
import Painting from './Painting';
import Egg from './Egg';
import TouchPoint from './TouchPoint';
import CompleteOverlay from './CompleteOverlay';
import Timer from './Timer';
import Score from './Score';
import basketBack from '../graphics/basket_Back.svg';
import next from '../graphics/EggHunt_NextButton.svg'
import { connect } from 'react-redux';
import { signOutUser } from '../firebase';

const Level = props => {
  const { eggs, scroll, base, overlay, level, toggleLevel, game } = props;
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    setComplete(false);
    if (!eggs.filter(({ found }) => !found).length) {
      setComplete(true);
    }
  }, [eggs, setComplete]);

  return <Canvas>
    <Painting
      width={100}
      scrollX={0}
      scrollY={scroll}
      base={base}
      overlay={overlay}
    >
      <svg viewBox="0 0 60 80" width="18" height="18" x="83.5" y="43">
        <image href={basketBack} width="55" x="2" y="20" />
      </svg>
      {eggs.map((e, index) => <Egg key={index} {...{ ...e, level, index }} />)}
      <Score />
    </Painting>
    {eggs.map((e, index) => <TouchPoint key={index} {...{ ...e, level, index }} />)}
    {complete && <CompleteOverlay />}
    <svg viewBox="0 0 60 26" width="13" height="5" x="83" y="1">
      <image style={{
        fill: '#fff',
        fontWeight: 'bold',
        alignText: 'center'
      }} x="14" y="6" width={50}
        onClick={toggleLevel}
        href={next}
      />
    </svg>
    <Timer />
    {!game && <rect width={100} height={65} x={0} fill="#ffffff99" clip-path="url(#clip)" />}
    <svg x={5} y={51} stroke='#fff' fill='#fff' xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 76 24">
      <path className='fill-blue stroke-blue' d="M19,3 C20.11,3 21,3.9 21,5 L21,8 L19,8 L19,5 L5,5 L5,19 L19,19 L19,16 L21,16 L21,19 C21,20.1 20.11,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,5 C3,3.9 3.9,3 5,3 L19,3 Z M15.5,17 L20.5,12 L15.5,7 L14.09,8.41 L16.67,11 L7,11 L7,13 L16.67,13 L14.09,15.59 L15.5,17 Z" />
      <text x={25} y={17} className='fill-blue stroke-blue'>log out</text>
      <rect width={100} height={100} fill='transparent' stroke='none' onClick={signOutUser}></rect>
    </svg>
  </Canvas>;
};

export default connect(({game}) => ({game}), d => ({ toggleLevel: () => d({ type: 'SWITCH_LEVEL' }) }))(Level);