import React from 'react';

const Image = props => <>
  <image
    width={props.width}
    x={props.scrollX || 0}
    y={props.scrollY || 0}
    href={props.image}
    
    // #clip is defined in Canvas component.
    clip-path="url(#clip)"
  />
</>;

export default Image;
