import React from 'react';
import { useSelector } from 'react-redux';
import Reveal from './Reveal';
import Spinner from './Spinner';

const Loading = () => {
  const { loading } = useSelector(({ loading }) => ({ loading }));

  return loading ? <Reveal duration={500}>
    <div className='w-screen h-full min-h-screen bg-purple flex items-center justify-center overflow-hidden fixed top-0 left-0 z-50'>
      <Spinner />
    </div>
  </Reveal> : null;
};

export default Loading;
