import { useSelector } from "react-redux";
import Toast from "./Toast";

const Toasts = () => {
  const { toasts } = useSelector(({ toasts }) => ({ toasts }))
  return <div className='flex flex-col space-y-4 fixed top-4 right-4'>
    {toasts.map((t, key) => <Toast {...{ ...t, key }} />)}
  </div>;
};

export default Toasts;