import fb from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/analytics";

fb.initializeApp({
  apiKey: "AIzaSyAVylCMZaRL7_REqBYE-TzXn2QPsRA6vxs",
  authDomain: "egg-hunt-7f7bd.firebaseapp.com",
  databaseURL: "https://egg-hunt-7f7bd.firebaseio.com",
  projectId: "egg-hunt-7f7bd",
  storageBucket: "egg-hunt-7f7bd.appspot.com",
  messagingSenderId: "1053589180589",
  appId: "1:1053589180589:web:146424bb4ad95cd206f624",
  measurementId: "G-H4DG3SKFCE"
});

fb.analytics();

const currentUserUid = () => fb.auth().currentUser.uid;

export const signOutUser = async () => fb.auth().signOut();

export const getDownloadLink = async ref => fb.storage().ref(ref).getDownloadURL();

export const getOrg = async ({ id, key, justDoc = false }) => {
  // if (justDoc) {
  //   console.log('Short!')
  //   const doc = await fb.firestore().collection('orgs').doc(id).get();
  //   return doc.data();
  // }

  const { data } = await fb.functions().httpsCallable('getOrganization')({ id, key, justDoc });
  return data;
};

export const getOrgList = async () => {
  const { data } = await fb.functions().httpsCallable('getOrganizationList')();
  return data;
};

export const createUser = async d => {
  const { data } = await fb.functions().httpsCallable('createUser')(d);

  await fb.auth().signInWithEmailAndPassword(data.email, 'egg-hunt');

  return data;
};

export const createOrg = async d => {
  const { data } = await fb.functions().httpsCallable('createOrganization')(d);
  return data;
};

export const sendOrgKey = async ({ email }) => {
  const { data } = await fb.functions().httpsCallable('sendOrganizationKey')({ email });
  return data;
};

export const createPaymentIntent = async ({ amount }) => fb.functions().httpsCallable('createPaymentIntent')({ amount });

export const authStateListener = async cb => fb.auth().onAuthStateChanged(cb);

export const getCurrentUserData = async () => {
  const doc = await fb.firestore().collection('users').doc(currentUserUid()).get();
  return doc.data();
};

export const orgExists = async id => {
  const { data } = await fb.functions().httpsCallable('organizationExists')({ id });
  return data;
};

export const getNumberOfTries = async () => {
  try {
    const res = await fb.firestore().collection('users').doc(currentUserUid()).get();

    return res.data().tries;

  } catch (error) {
    return 5;
  }
};

export const createPlayer = async ({ score, initials }) => {
  const batch = fb.firestore().batch();
  const uid = currentUserUid();

  if (!uid) return null;

  const userRef = fb.firestore().collection('users').doc(uid);

  const tries = await getNumberOfTries();

  if (tries <= 0) return 0;

  batch.update(userRef, {
    tries: fb.firestore.FieldValue.increment(-1)
  });

  const userPlayerRef = fb.firestore().collection('users').doc(uid).collection('players').doc();

  batch.set(userPlayerRef, {
    score,
    initials
  });

  await batch.commit();

  return tries - 1;
};

export const addScore = async ({ score }) => {
  const user = await fb.firestore().collection('users').doc(currentUserUid()).get();
  const unfinshedPlayerDocs = await fb.firestore().collection('users').doc(currentUserUid()).collection('players').where('score', '==', null).get();

  if (unfinshedPlayerDocs.docs.length <= 0) return;

  const unfinshed = unfinshedPlayerDocs.docs[0];

  unfinshed.ref.update({ score });

  const { org, email, firstName, lastName } = user.data();

  return fb.firestore().collection('players').doc(unfinshed.id).set({
    ...unfinshed.data(),
    score,
    user: currentUserUid(),
    org,
    email,
    firstName,
    lastName
  });
};

export const topTenForOrg = async () => {
  const { data } = await fb.functions().httpsCallable('topTenForOrganization')({ uid: currentUserUid() });
  return data;
};
